export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((member, i) => (
                <div key={`${member.name}-${i}`} className='col-md-4 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={member.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{member.name}</h4>
                      <ul>{member.social ? member.social.map((value) => (<li key={i + value.name}><a href={value.url}><i className={"fa fa-" + value.name}></i>
            </a>
          </li>)): <br/>}</ul>
                      <p>{member.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
