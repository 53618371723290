export const Footer = (props) => {
  return (
    <footer>
      <div id='footer'>
        <div className='container text-center'>
          <p>
          Copyright &copy; 4dcoders {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  )
}
